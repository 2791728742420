import {forkJoin as observableForkJoin,  Observable ,  Subscription ,  Subject } from 'rxjs';
import {refCount, publishReplay} from 'rxjs/operators';
declare var require: any;
import { MessageService } from './message/message.service';
import { Design, DesignSettingsBackground, DesignSettingsFont } from '../../models/design';
import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageDataService } from '../shared/services/local-storage-data.service';
import { GenericCrudService } from '../shared/services/generic-crud.service';
import {HttpClient} from '@angular/common/http';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {UserSessionService} from './service/user-session.service';

const Sass = require('./../../../node_modules/sass.js/dist/sass.js');

@Injectable()
export class StyleService {
  @BlockUI() public blockUI: NgBlockUI;

  public static readonly CURRENT_STORAGE_DESIGN = 'design-storage';
  public static readonly CURRENT_STORAGE_DESIGN_CSS = 'design-storage-css';
  public static readonly MAIN_ELEMENT_ID = 'HRAppStyles';

  private worker: Worker;

  private _design: Design;
  private _preview = false;

  designChanged: EventEmitter<any> = new EventEmitter<any>();
  designThemeChanged: EventEmitter<Design> = new EventEmitter<Design>();
  designLogoChanged: EventEmitter<Design> = new EventEmitter<Design>();

  private scssDesignVars: string;
  private scssDesignUiVars: string;
  private scssDesignHelpers: string;
  private scssDesign: string;

  private importsLeft = 0;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private genericCrudService: GenericCrudService,
    private userSession: UserSessionService
  ) {
    this.designChanged.subscribe((options: any) => {
      if (options.design) {
        this.compileDesign(options.design, options.cache, options.css);
      } else {
        // reset to default and clear cache.
        this.userSession.remove(StyleService.CURRENT_STORAGE_DESIGN).remove(StyleService.CURRENT_STORAGE_DESIGN_CSS);
        this.removeElement('HRAppStyles');
        this.addFavIcon('favicon.png');

        this.designLogoChanged.next(options.design);
      }
    });

    this.http.get(`styles/scss/_variables.scss`, {responseType: 'text'}).pipe(
      publishReplay(1),
      refCount())
      .subscribe((text: string) =>
        this.scssDesignVars = text.replace(new RegExp(/(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/.*)/g), '')
      );

    this.http.get(`styles/scss/ui/_variables.scss`, {responseType: 'text'}).pipe(
      publishReplay(1),
      refCount())
      .subscribe((text: string) =>
        this.scssDesignUiVars = text.replace(new RegExp(/(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/.*)/g), '')
      );

    this.scssDesign = '';

    this.loadAndImportScssWithHttp('_helpers.scss', 'styles/scss');

    this.initWebWorker();
    this.loadAndImportScssWithHttp('application.scss', 'styles/scss-dynamic');
  }

  private initWebWorker() {
    this.worker = new Worker('workers/sass-compile.worker.js');

    this.worker.addEventListener('message', (e: MessageEvent) => {
      console.log('worker sent message:', e.data);
    });
  }

  protected loadAndImportScssWithHttp(scssFile: string, scssRoot: string) {
    const importRegex = /@import[\s]*["'](.*)["'];/g;

    this.http.get(`${scssRoot}/${scssFile}`, {responseType: 'text'}).pipe(
      publishReplay(1),
      refCount())
      .subscribe((text: string) => {
        const scss = text.replace(new RegExp(/(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/.*)/g), '');

        this.scssDesign += scss;

        this.importsLeft = (scss.match(importRegex) || []).length;

        if (this.importsLeft > 0) {

          const scanAndloadScssRecursive = (scssR) => {
            let m;

            const httpObservables = [];

            while ((m = importRegex.exec(scssR)) !== null) {
              // This is necessary to avoid infinite loops with zero-width matches
              if (m.index === importRegex.lastIndex) {
                importRegex.lastIndex++;
              }

              let scssFilepath: string = m[1];
              let scssFilename: string;

              scssFilename = scssFilepath;
              if (scssFilename.lastIndexOf('/') !== -1) {
                scssFilename = scssFilename.substr(scssFilename.lastIndexOf('/') + 1);
                scssFilepath = scssFilepath.substr(0, scssFilepath.lastIndexOf('/') + 1);
              }

              if (scssFilename.substr(0, 1) !== '_') {
                scssFilename = `_${scssFilename}`;
              }

              if (scssFilename.substr(-4) !== 'scss') {
                scssFilename = `${scssFilename}.scss`;
              }

              const scssRoute = `${scssRoot}/${scssFilepath}${scssFilename}`.replace('../', '').replace('./', '');
              httpObservables.push(
                this.http.get(scssRoute, {responseType: 'text'}).pipe(
                  publishReplay(1),
                  refCount())
              );
            }

            observableForkJoin(httpObservables).subscribe((res) => {
              const scssArr = res.map(
                (responseData: any) => responseData
                  .replace(new RegExp(/(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/.*)/g), '')
              );

              let cnt = 0;
              const importCssArr: Array<String> = [];

              while ((m = importRegex.exec(scssR)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === importRegex.lastIndex) {
                  importRegex.lastIndex++;
                }

                importCssArr.push(m[0]);

                cnt++;
              }

              scssArr.map((value, index) => {
                this.scssDesign = this.scssDesign.replace((
                  (importCssArr[index].match(/\$[a-zA-z0-9]+/g) || []).length !== -1 ?
                    `${importCssArr[index]}` :
                    ``
                ), value);

                this.importsLeft--;

                const importMatches: number = (value.match(importRegex) || []).length;

                if (importMatches > 0) {
                  this.importsLeft += importMatches;
                  scanAndloadScssRecursive(value);
                }

              });

              if (!this.importsLeft) {
                // this.scssSource.complete();
              }

            });
          };
          scanAndloadScssRecursive(scss);
        } else {
          if (!this.importsLeft) {
            // this.scssSource.complete();
          }
        }
      });
  }

  public compileDesign(design: Design, cache: boolean = true, cssSource: any) {

    this.design = Design.sanitize({
      settings: design.settings
    });

    if (cache) {
      this.userSession.set(StyleService.CURRENT_STORAGE_DESIGN, {
        value: this.design
      });
    }

    if (cssSource) {
      this.compileCss(cssSource, cache);

      this.addFavIcon(this.design.settings.global.favicon);
    } else {
      let oneSecondTimer;

      if (oneSecondTimer) {
        clearTimeout(oneSecondTimer);
      }

      oneSecondTimer = window.setTimeout(() => { this.compileScss(cache); }, 1);
    }
  }

  protected compileScss(cache: boolean = false) {
    const me = this;

    me.blockUI.start('Design wird geladen');

    const minimizeData = (_content) => {
      let content = _content;
      content = content.replace(/\/\*(?:(?!\*\/)[\s\S])*\*\/|[\r\n\t]+/g, '');
      content = content.replace(/ {2,}/g, ' ');
      content = content.replace(/ ([{:}]) /g, '$1');
      content = content.replace(/([;,]) /g, '$1');
      content = content.replace(/ !(?!default)/g, '!');
      return content;
    };

    const addScssVar = (varName, varValue): string => {
      if (typeof (varValue) !== 'undefined') {
        return `$${varName}: ${varValue};` + '\n';
      }
      return '';
    };

    const addScssFontVars = (cssPrefix, valueObj: DesignSettingsFont): string => {
      let scssVars = '';
      scssVars += addScssVar(`${cssPrefix}-color`, valueObj.color);
      scssVars += addScssVar(`${cssPrefix}-size`, valueObj.size);
      scssVars += addScssVar(`${cssPrefix}-family`, valueObj.family);
      return scssVars;
    };

    const addScssBackgroundVars = (cssPrefix, valueObj: DesignSettingsBackground): string => {
      let scssVars = '';
      if (valueObj.color) {
        scssVars += addScssVar(`${cssPrefix}-color-dark`, valueObj.color);
        if (!valueObj.gradient) {
          scssVars += addScssVar(`${cssPrefix}-color`, valueObj.color);
          scssVars += addScssVar(`${cssPrefix}-color-light`, valueObj.color);
        } else {
          if (valueObj.colorSecond) {
            scssVars += addScssVar(`${cssPrefix}-color`, valueObj.colorSecond);
          }
          if (valueObj.colorThird) {
            scssVars += addScssVar(`${cssPrefix}-color-light`, valueObj.colorThird);
          }
        }
      }
      if (valueObj.color && !valueObj.image.url) {
        scssVars += addScssVar(`${cssPrefix}-image`, '""');
      } else if (valueObj.image.url) {
        scssVars += addScssVar(`${cssPrefix}-image`, `'${valueObj.image.url}'`);

        if (!valueObj.image.positionX && !valueObj.image.positionY) {
          const imageOptions = `top left ${valueObj.image.repeat ? 'repeat' : 'no-repeat'}`;
          scssVars += addScssVar(`${cssPrefix}-image-options`, imageOptions);
        } else {
          const imageOptions = `${valueObj.image.positionY} ${valueObj.image.positionX} ${valueObj.image.repeat ? 'repeat' : 'no-repeat'}`;
          scssVars += addScssVar(`${cssPrefix}-image-options`, imageOptions);
        }
      }

      return scssVars;
    };


    let customScssVars = `
$url-base-path: '../..';
$header-background-image: '';
$primary-color: ${this.design.settings.global.primaryColor};
`;

    customScssVars += addScssFontVars('default-font', this.design.settings.global.font);

    customScssVars += addScssFontVars('header-font', this.design.settings.header.font);
    customScssVars += addScssBackgroundVars('header-background', this.design.settings.header.background);

    customScssVars += addScssFontVars('menu-font', this.design.settings.menu.font);
    customScssVars += addScssBackgroundVars('menu-background', this.design.settings.menu.background);
    customScssVars += addScssBackgroundVars('menu-submenu-background', this.design.settings.menu.submenu.background);

    customScssVars += addScssBackgroundVars('main-tabrow-background', this.design.settings.mainTabrow.background);
    customScssVars += addScssFontVars('main-tabrow-tabs-font', this.design.settings.mainTabrow.tabs.font);
    customScssVars += addScssBackgroundVars('main-tabrow-tabs-background', this.design.settings.mainTabrow.tabs.background);
    customScssVars += addScssFontVars('main-tabrow-tab-selected-font', this.design.settings.mainTabrow.tabs.fontSelected);
    customScssVars += addScssBackgroundVars('main-tabrow-tab-selected-background', this.design.settings.mainTabrow.tabs.backgroundSelected);

    customScssVars += addScssFontVars('body-font', this.design.settings.body.font);
    customScssVars += addScssBackgroundVars('body-background', this.design.settings.body.background);

    customScssVars += addScssBackgroundVars('grid-row-background', this.design.settings.grid.rowBackground);

    customScssVars += addScssFontVars('ui-font', this.design.settings.ui.content.font);
    customScssVars += addScssBackgroundVars('ui-background', this.design.settings.ui.content.background);
    customScssVars += addScssBackgroundVars('ui-btn-background', this.design.settings.ui.content.backgroundButton);

    customScssVars += addScssFontVars('ui-header-font', this.design.settings.ui.header.font);
    customScssVars += addScssBackgroundVars('ui-header-background', this.design.settings.ui.header.background);

    const scssSource = `${customScssVars}${this.scssDesignVars}${this.scssDesignUiVars}${minimizeData(this.scssDesign)}`.trim();

    if (scssSource) {
      this.compileScssSource(scssSource, cache, (done) => {
        this.addFavIcon(me.design.settings.global.favicon);
      });
    }
  }

  public compileCss(css: any, cache: boolean = false) {
    let element = document.getElementById(StyleService.MAIN_ELEMENT_ID);
    const elementExists = (element);

    if (!elementExists) {
      element = document.createElement('style');
      element.id = StyleService.MAIN_ELEMENT_ID;
    }

    element.innerText = css;

    if (!elementExists) {
      document.head.appendChild(element);
    }
  }

  public compileScssSource(scssSource, cache: boolean = false, callback: any) {
    const me = this,
      sass = new Sass('workers/sass.worker.js');

    me.blockUI.start('Design wird geladen');

    sass.options({
      sourceMapContents: false,
      style: Sass.style.compressed,
      precision: -1,
      comments: false,
      indent: '  ',
      linefeed: '\n',
    });
    sass.compile(scssSource, function(cssResponse) {
      const css = cssResponse.text;

      if (cache && css) {
        me.userSession.set(StyleService.CURRENT_STORAGE_DESIGN_CSS, {
          value: css
        });
      }

      me.compileCss(css, true);

      sass.destroy();

      // xcentric, fix it!
      // me.blockUI.reset();

      return callback();
    });
  }

  addFavIcon(iconUrl: string) {
    const oldFavIcon = document.querySelector('link[rel=icon]');
    if (oldFavIcon) {
      oldFavIcon.remove();
    }
    // @todo doing it twice is not very comfortable
    const oldFavIconIco = document.querySelector('link[rel=icon]');
    if (oldFavIconIco) {
      oldFavIconIco.remove();
    }

    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = iconUrl || 'favicon.ico';
    link.type = 'image/png';

    document.head.appendChild(link);

    // @todo Convert PNG to ICO for <IE11. At the moment doing it only for the default favicon
    if (iconUrl === 'favicon.png') {
      const linkIco = document.createElement('link');
      link.rel = 'icon';
      link.href = 'favicon.ico';
      link.type = 'image/x-icon';
      document.head.appendChild(link);
    }

    this.designLogoChanged.next(this.design);
  }

  removeElement(id: string) {
    const oldStyle = document.getElementById(id);
    if (oldStyle) {
      oldStyle.remove();
    }
  }

  get design(): Design {
    return this._design;
  }

  set design(value: Design) {
    this._design = value;
  }

  public setDesignBasedOnOrganisation(organisation): void {
    this.genericCrudService
      .getEntities(`superadmin/organisations/${organisation.id}/designs`, '', {
        embedded: 'none'
      })
      .subscribe((designs) => {
        let design = null;

        if (designs && designs.length > 0) {
          design = designs.shift();
        }

        this.designChanged.next({
          design: design,
          cache: design !== null
        });


        // xcentric, fix it!
        console.log('this.blockUI.reset();');
      });
  }
}
