import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/primeng';

export class WorkHourPeriodGenerateEntriesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doGenerate(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  public doGenerate(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const employee = this.getCurrentFilterValue(component);
    const year = this.getFilterValue(component, 'year');
    const month = this.getFilterValue(component, 'month');
    const url = 'phoenix/workhourperiodentries/import?year=' + (year || '') + '&month=' + (month || '') + '&leasedEmployee=' + (employee ? employee.id : '') + '&embedded=none';

    const getUrl = 'phoenix/workhourperiodentries/manuallychanged'

    return this.genericCrudService.get(getUrl, {
      'workMonth': month,
      'workYear': year,
      'leasedEmployeeId': employee.id
    }).pipe(
      map((response) => {
        if (response && response.isManuallyChanged) {
          this.getConfirmationService().confirm({
            acceptVisible: true,
            rejectVisible: true,
            header: this.getTranslationService().instant('WORK_HOUR.MANUALLY_CHANGED_HEADER'),
            message: this.getTranslationService().instant('WORK_HOUR.MANUALLY_CHANGED_BODY'),
            accept: () => {
              return this.generatePeriodEntries(url).subscribe(() => {
                component.loadEntities().subscribe();
              });
            },
            reject: () => {}
          });
        } else if (response && response.isNoEntries) {
          this.generatePeriodEntries(url).subscribe(() => {
            component.loadEntities().subscribe();
          });
        }
        return { status: true, content: ''};
      })
    );
  }

  protected generatePeriodEntries(url: string): Observable<ExecutionStepStatus> {
    return this.genericCrudService.customPost(url, {})
      .pipe(
        map((response) => {
          if (!response.error) {
            this.injector.get(MessageGrowlService).info(
              this.injector.get(TranslateService).instant('WORK_HOUR.ENTRIES_GENERATED')
            );
          } else {
            this.injector.get(MessageGrowlService).info(
              this.injector.get(TranslateService).instant('WORK_HOUR.' + response.error)
            );
          }

          return { status: true, content: ''};
        })
      );
  }

  private getFilterValue(component: AbstractGenericGridComponent, item: string): string|null {
    return component.gridFilters[item] && component.gridFilters[item].value ? component.gridFilters[item].value : null;
  }

  private getCurrentFilterValue(component: AbstractGenericGridComponent): any|null {
    return component.gridFilters.leasedEmployee && component.gridFilters.leasedEmployee.value &&
      component.gridFilters.leasedEmployee.value[0] ?
      component.gridFilters.leasedEmployee.value[0] :
      null;
  }

  private getConfirmationService(): ConfirmationService|null {
    return this.injector.get(ConfirmationService, null);
  }

  private getTranslationService(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
