import {AfterViewInit, Component} from '@angular/core';
import {WebWorkerService} from 'angular2-web-worker';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {AbstractCustomFilterClassComponent} from '../abstract-custom-filter-class.component';
import {SelectItem} from 'primeng/api';
import {EntityDataStoreService} from '../../../../services/entity-data-store.service';
import {
  workHourColumnManipulatorRunner, WorkHourColumnManipulatorRunnerData
} from '../../../../../web-worker/runners/workhour-column-manipulator-runner';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-workhour-dropdown-column-manipulator',
  templateUrl: './workhour-dropdown-column-manipulator.component.html',
  providers: [WebWorkerService]
})
export class WorkhourDropdownColumnManipulatorComponent extends AbstractCustomFilterClassComponent implements AfterViewInit {

  public industrialTime = false;
  public workLengthIndustrialTime = false;
  public entryType = 'Normal';

  public entryTypeOptions: SelectItem[] = [
    {label: 'Normal', value: 'Normal'},
    {label: 'Schnellerfassung', value: 'Schnellerfassung'},
    {label: 'Erweitert', value: 'Erweitert'},
    {label: 'Dauer', value: 'Pause'},
  ];

  public managingColumns: any = {
    workStartIndustry:        {index: 1, type: ['Normal', 'Erweitert', 'Pause'], industrialTime: true},
    workEndIndustry:          {index: 2, type: ['Erweitert'], industrialTime: true},
    workStart2Industry:       {index: 3, type: ['Erweitert'], industrialTime: true},
    workEnd2Industry:         {index: 4, type: ['Normal', 'Erweitert', 'Pause'], industrialTime: true},
    workBreakIndustry:        {index: 5, type: ['Normal', 'Pause'], industrialTime: true},
    workLengthIndustry:       {index: 6, type: ['Normal', 'Erweitert', 'Schnellerfassung', 'Pause'], industrialTime: true},
    workStart:                {index: 7, type: ['Normal', 'Erweitert', 'Pause'], industrialTime: false},
    workEnd:                  {index: 8, type: ['Erweitert'], industrialTime: false},
    workStart2:               {index: 9, type: ['Erweitert'], industrialTime: false},
    workEnd2:                 {index: 10, type: ['Normal', 'Erweitert', 'Pause'], industrialTime: false},
    workBreak:                {index: 11, type: ['Normal', 'Pause'], industrialTime: false},
    workLength:               {index: 12, type: ['Normal', 'Erweitert', 'Schnellerfassung', 'Pause'], industrialTime: false},
    workLengthPlanned:        {index: 13, type: ['Normal', 'Erweitert', 'Schnellerfassung', 'Pause'], industrialTime: false},
    workLengthPlannedIndustry: {index: 14, type: ['Normal', 'Erweitert', 'Schnellerfassung', 'Pause'], industrialTime: true}
  };

  public disabledColumns: any = {
    workStartIndustry:        {type: []},
    workEndIndustry:          {type: []},
    workStart2Industry:       {type: []},
    workEnd2Industry:         {type: []},
    workBreakIndustry:        {type: ['Pause']},
    workLengthIndustry:       {type: ['Erweitert']},
    workStart:                {type: []},
    workEnd:                  {type: []},
    workStart2:               {type: []},
    workEnd2:                 {type: []},
    workBreak:                {type: ['Pause']},
    workLength:               {type: ['Erweitert']},
    workLengthPlanned:        {type: ['Normal', 'Pause']},
    workLengthPlannedIndustry: {type: ['Normal', 'Pause']}
  };

  constructor(
    protected permissionService: PermissionService,
    protected entityDataStore: EntityDataStoreService,
    protected webWorkerService: WebWorkerService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.component.filterComponent.onSearchExecuted.subscribe(() => {
        const promise = this.createPromise();

        promise.then((runnerData: WorkHourColumnManipulatorRunnerData) => {
          this.onRunnerResolved(runnerData);
        });

        this.updateApiRoute();

        this.calculate().subscribe();
      });
    }, 1000);
  }

  protected calculate(): Observable<any> {
    return this.genericCrudService.customPost('phoenix/workhours/calculate/month', {
      'leasedEmployee': this.component.gridFilters['leasedEmployee'].value[0],
      'workMonth': this.component.gridFilters['workMonth'].value,
      'workYear': this.component.gridFilters['workYear'].value
    });
  }

  public onChangeEntryType(): void {
    this.updateApiRoute();

    if (this.component.gridFilters['leasedEmployee']) {
      this.component.filterComponent.onSearch();
    }
  }

  public onChangeIndustrialTime(): void {
    this.updateApiRoute();

    if (this.industrialTime) {
      this.workLengthIndustrialTime = false;
    }
  }

  public onChangeWorkLengthIndustrialTime(): void {

  }

  private updateApiRoute() {
    this.component.apiRoutAdditionalParameters['entryType'] = this.entryType;
    this.component.apiRoutAdditionalParameters['industrialTime'] = this.industrialTime;
  }

  public onRunnerResolved(data: WorkHourColumnManipulatorRunnerData): void {

    setTimeout(() => {
      this.component.columns = data.columns;
      this.managingColumns = data.managingColumns;

      ChangeDetectorRefHelper.detectChanges(this.component);
    }, 1);
  }

  private createPromise(): Promise<any> {
    return this.webWorkerService.run(workHourColumnManipulatorRunner, {
      columns: this.component.columns,
      fields: this.component.fields,
      managingColumns: this.managingColumns,
      disabledColumns: this.disabledColumns,
      visibleColumns: [],
      industrialTime: this.industrialTime,
      workLengthIndustrialTime: this.workLengthIndustrialTime,
      entryType: this.entryType
    });
  }
}
