// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { disableDebugTools } from '@angular/platform-browser';
import { enableProdMode, ApplicationRef } from '@angular/core';

export const BASE_URL = '/backend';
export const DOWNLOAD_URL = BASE_URL;
export const UPLOAD_URL = `${BASE_URL}/assets/uploads`;
export const API_URL = `${BASE_URL}/api`;
export const PDF_VIEWER_URL = `/pdfviewer/api/pdfviewer`;

const DUMMY_USERNAME = 'dev@hr-puzzle.dev';
const DUMMY_USERPASS = '123!';

export const environment: Environment = {
  production: true,
  apiUrl: API_URL,
  baseUrl: BASE_URL,
  downloadUrl: DOWNLOAD_URL,
  memoModuleId: 560,
  memoWithPastRemindersModuleId: 561,
  memoWithResponsibleUserGroupsModuleId: 563,
  uploadUrl: UPLOAD_URL,
  pdfViewerUrl: PDF_VIEWER_URL,
  consoleErrorReporting: false,
  //froalaKey: 'CA4B3A2C2zE2A1A9A7A9A1B1A5E1B4mzaB3ecmbuC-7I2yh==',
  froalaKey: '2E3B9A5B5A5B4G5aG3C2A5C4C4D3C2D4G2H2tFOFSAGLUi1AVKd1SN==',
  dummyUser: <App.User.Dummy>{
    email: DUMMY_USERNAME,
    password: DUMMY_USERPASS
  },
  additional: {
    cockpitFirstButtonModuleId: 952,
    cockpitSecondButtonModuleId: 879,
  },
  key: 'pcp',
  logging: true,
  algoliaAppId: 'plKT9Z8C1W5X',
  algoliaApiKey: 'f981d394eb4e45f76a94416d87d30348',
  geoapifyApiKey: 'bc55bba5cfbd420587523d20a0124116',

  myTodoModuleUrl: 'phoenix/18/module/530/part-module/640',
  groupTodoModuleUrl: 'phoenix/18/module/530/part-module/686',
  iCreatedTodoModuleUrl: 'phoenix/18/module/530/part-module/687',
  branchOfficeTodoModuleUrl: 'phoenix/18/module/530/part-module/691',
  questionnaireTodoModuleUrl: 'phoenix/18/module/530/part-module/808',

  myTodoBackendUrl: 'phoenix/todos/offset/0/limit/50/orderby/id/asc?todoState=1&responsibleUser=__CURENT_USER__',
  groupTodoBackendUrl: 'phoenix/todos/offset/0/limit/50/orderby/id/asc?todoState=1&responsibleGroup=responsibleGroups:0',
  iCreatedTodoBackendUrl: 'phoenix/todos/offset/0/limit/50/orderby/id/asc?createdBy=__CURENT_USER__',
  branchOfficeTodoBackendUrl: 'phoenix/todos/offset/0/limit/50/orderby/id/asc?todoState=1&responsibleBranchOffice=__CURRENT_BRANCH_OFFICE_ID__',
  questionnaireTodoBackendUrl: 'phoenix/todos/offset/0/limit/50/orderby/id/asc?todoState=1&responsibleUser=__CURENT_USER__',

  myTicketModuleUrl: 'ticket/24/module/719/part-module/722',
  openTicketModuleUrl: 'ticket/24/module/719/part-module/720',
  workingTicketModuleUrl: 'ticket/24/module/719/part-module/721',
  overdueTicketModuleUrl: 'ticket/24/module/719/part-module/723',

  myTicketBackendUrl: 'ticket/tickets/offset/0/limit/50/orderby/id/asc?ticketUser=__CURENT_USER__&ticketDepartment=ticketDepartment:0&ticketState=notEqual:3',
  openTicketBackendUrl: 'ticket/tickets/offset/0/limit/50/orderby/id/asc?ticketState=1&ticketDepartment=ticketDepartment:0',
  workingTicketBackendUrl: 'ticket/tickets/offset/0/limit/50/orderby/id/asc?ticketState=2&ticketDepartment=ticketDepartment:0',
  overdueTicketBackendUrl: 'ticket/tickets/offset/0/limit/50/orderby/id/asc?isOverDue=1',

  newDocumentsModuleUrl: 'dms/28/module/813/part-module/814',
  myDocumentsModuleUrl: 'dms/28/module/813/part-module/709',
  groupDocumentsModuleUrl: 'dms/28/module/813/part-module/816',

  newDocumentsBackendUrl: 'dms/dmsfiles/offset/0/limit/50/orderby/id/asc?isWorkflowReady=0&createdByBranchOffice=__CURRENT_BRANCH_OFFICE_ID__',
  myDocumentsBackendUrl: 'dms/dmsfiles/offset/0/limit/50/orderby/id/asc?responsibleUser=__CURENT_USER__',
  groupDocumentsBackendUrl: 'dms/dmsfiles/offset/0/limit/50/orderby/id/asc?name=DmsFilePermission:my'
};

// @todo Do we still need this?
let _decorateModuleRef = function identity<T>(value: T): T { return value; };
_decorateModuleRef = (modRef: any) => {

  disableDebugTools();
  enableProdMode();
  const _ng = (<any>window).ng;
  (<any>window).ng.probe = _ng.probe;
  (<any>window).ng.coreTokens = _ng.coreTokens;
  return modRef;
};

export const decorateModuleRef = _decorateModuleRef;
export const env: Environment = environment; // Just an alias for shorter code
