import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {GenericCrudService} from '../../../../shared/services/generic-crud.service';
import {GenericDialogModuleService} from '../../../../shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {
  ModuleCloseAction,
  ModuleCloseComponentAction,
  ModuleCloseService
} from '../../../../shared/content-renderer/services/navigation/module-close.service';
import {ModulesStateService} from '../../../../shared/content-renderer/services/modules-state.service';

@Component({
  selector: 'ul[app-generic-menu-item]',
  templateUrl: './generic-menu-item.component.html',
  styleUrls: ['./generic-menu-item.component.scss']
})
export class GenericMenuItemComponent implements OnInit, OnDestroy {

  @Input() items: any[];
  @Input() isFirstLevel = true;
  @Input() iconsPath = '';
  @Input() menuPosition = 'left';

  public levelIcon = 'fa fa-caret-down';

  public hasIcons = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private genericCrudService: GenericCrudService,
    private genericDialogModuleService: GenericDialogModuleService,
    private modulesStateService: ModulesStateService,
    private moduleCloseService: ModuleCloseService
  ) { }

  ngOnInit() {
    this.setArrowIcon();
    this.hasIcons = this.itemsHaveIcon();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public onMouseOver(event) {
    if (event.target.querySelector('ul')) {
      event.target.querySelector('ul').style.display = 'inherit';

      const out: any = this.isOutOfBounds(event.target.querySelector('ul'));

      if (out.bottom && out.bottomPx > 0) {
        event.target.querySelector('ul').style.top = -out.bottomPx + 'px';
      }
    }
  }

  public onMouseLeave(event) {
    if (event.target.querySelector('ul')) {
      event.target.querySelector('ul').style.display = 'none';
    }
  }

  private setArrowIcon() {
    if (!this.isFirstLevel) {
      this.levelIcon = 'fa fa-caret-right';
    }
  }

  private isOutOfBounds(elem): any {
    const bounding = elem.getBoundingClientRect();

    return {
      bottom: bounding.bottom > (window.innerHeight || document.documentElement.clientHeight),
      bottomPx: bounding.bottom - (window.innerHeight || document.documentElement.clientHeight)
    };
  }

  public doShowImageContainer(item) {
    let doShow = !!item.iconPath;

    if (this.isFirstLevel && !item.iconPath) {
      doShow = false;
    }

    if (!this.isFirstLevel && this.hasIcons) {
      doShow = true;
    }

    return doShow;
  }

  public onItemClick(item) {
    if (!this.isFirstLevel) {
      this.elementRef.nativeElement.style.display = 'none';
    }

    // do not change route when we click on items which have subitems
    if (item.items && item.items.length > 0) {
      return false;
    }

    if (item.isReportMenuItem) {
      item.routerLink[0] = 'phoenix/18';
      item.routerLink[0] = item.routerLink[0] + '/report/' + item.reportMenuItem;
    }

    if (item.routerLink && item.routerLink.length > 0) {
      if (item.openInTab) {
        this.openModuleInTab(item);
      } else {
        this.openModuleInDialog(item);
      }
    }

    return false;
  }

  /**
   * check if some of the items have icon,
   * we need it to check should we show `item-image-container` or not
   */
  private itemsHaveIcon() {
    let haveIcons = false;

    this.items.forEach(item => {
      if (item.iconPath) {
        haveIcons = true;
      }
    });

    return haveIcons;
  }

  private openModuleInTab(menu): void {
    const currentModuleState = this.modulesStateService.getCurrent();

    if (currentModuleState) {
      this.moduleCloseService.close(currentModuleState, {
        componentActions: [
          ModuleCloseComponentAction.RemoveRequestCache,
          ModuleCloseComponentAction.RemoveFromElementsState,
          ModuleCloseComponentAction.RemoveFromDirtyStore,
          ModuleCloseComponentAction.SetPerformedActionClose
        ],
        moduleActions: [
          ModuleCloseAction.HideModuleDialog
        ],
        yes: () => {
          this.router.navigate([menu.routerLink[0]]).then();
        }
      });
    } else {
      this.router.navigate([menu.routerLink[0]]).then();
    }
  }

  private openModuleInDialog(menu) {
    const routeString = menu.routerLink[0],
      moduleId = +routeString.substr(routeString.indexOf('module/') + 7);

    if (moduleId) {
      this.subscriptions.push(
        this.genericCrudService.getEntity('superadmin/modules', moduleId).subscribe((module) => {

          this.genericDialogModuleService.hideDialog();
          this.genericDialogModuleService.showDialog(module, {
            height: menu.targetWindowHeight || 600,
            width: menu.targetWindowWidth || 800,
            isAutocompleteModuleState: false,
            onClose: () => {
              return true;
            }
          });
        })
      );
    }
  }
}
